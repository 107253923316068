
jQuery(document).ready(function ($) {
    $('body').off('click', '.delete-post-link').on('click', '.delete-post-link', function(e) {
        e.preventDefault();
        const postId = $(this).data('post-id');
        const nonce = $(this).data('nonce');

        // Afficher le loader
        $('.loader-container').show();

        $.ajax({
            type: 'POST',
            url: ajax_delete_post_obj.ajax_url,
            data: {
                action: 'delete_post',
                post_id: postId,
                nonce: nonce
            },
            success: function (response) {

                // Masquer le loader
                $('.loader-container').hide();

                if (response === 'success') {
                    $('.single_posts_modify_by_users[data-annonce-id="' + postId + '"]').fadeOut(300);
                    // Mettez à jour l'affichage en conséquence, par exemple en supprimant le post de la liste
                    alert('L\'annonce a été supprimée avec succès.');
                    //location.reload();
                } else {
                    $('.loader-container').hide();
                    alert('Une erreur est survenue lors de la suppression de l\'annonce.');
                }
            }
        });
    });
});